<template lang="pug">
  PageContainer
    template(v-slot:header)
      PageHeader(title="Группы мастеров")
    template(v-slot:content)
      EducationGroupsTable
</template>
<script>
import { Component } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import EducationGroupsTable from '@/components/tables/EducationGroupsTable.vue'

@Component({ components: { EducationGroupsTable } })
export default class GroupsList extends NotifyMixin {

}
</script>
