





































































import { Bind, Debounce } from 'lodash-decorators'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import UsersMultipleSearch from '@/components/_uikit/search/UsersMultipleSearch.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import ManagerGroupsModule from '@/store/modules/manager/groups'
import { CourseType, GroupType, MasterGroupUpdateRequest, RoleType, UserShortResource } from '@/store/types'
import UsersSingleSearch from '@/components/_uikit/search/UsersSingleSearch.vue'

@Component({
  components: {
    Dialog,
    ModalWrapper,
    TextInput,
    UsersMultipleSearch,
    UsersSingleSearch,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class EditMasterGroupModal extends Mixins(NotifyMixin, PermissionsMixin) {
  @Prop({ default: false })
  private visible!: boolean

  private mentors: UserShortResource[] = this.masterGroup ? [...this.masterGroup.mentors] : []
  private mentor: UserShortResource | null = !this.isSpecialCourse && this.masterGroup && this.masterGroup.mentors.length ? this.masterGroup.mentors[0] : null

  private form: MasterGroupUpdateRequest = {
    chatUrl: '',
    forAllGroups: false,
    groupUrl: '',
    title: '',
  }

  private get masterGroup () {
    return ManagerGroupsModule.group
  }

  private get isSpecialCourse () {
    return this.masterGroup && this.masterGroup.course.type.value === CourseType.SPECIAL
  }

  private get isMasterGroupPlus() {
    if (this.masterGroup) {
      return this.masterGroup.groupType.value === GroupType.PLUS
    }
    return false
  }

  private get roleTypes () {
    return this.isSpecialCourse ? [RoleType.SPECIAL_MENTOR] : this.isMasterGroupPlus ? [RoleType.PLUS_MENTOR] : [RoleType.MENTOR]
  }

  private mounted () {
    this.syncForm()
  }

  private syncForm () {
    if (this.masterGroup) {
      this.mentor = !this.isSpecialCourse && this.masterGroup.mentors.length ? this.masterGroup.mentors[0] : null
      this.form = {
        chatUrl: this.masterGroup.chatUrl,
        forAllGroups: false, // Чтобы случайно не сохранил наставников во всех группах спецкурса
        groupUrl: this.masterGroup.groupUrl,
        mentorIds: this.masterGroup.mentors.map((mentor: UserShortResource) => mentor.id),
        title: this.masterGroup.title,
      }
    }
  }

  private handleSelectMentors (users: UserShortResource[]) {
    this.form.mentorIds = users.map((user: UserShortResource) => user.id)
  }

  private handleSelectMentor (user: UserShortResource) {
    this.form.mentorIds = user ? [user.id] : []
  }

  @Debounce(300)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          if (this.masterGroup) {
            ManagerGroupsModule.saveGroup({
              masterGroupID: this.masterGroup.id,
              params: this.form,
            })
              .then(() => {
                this.notifySuccess('Группа мастеров обновлена')
                this.$emit('success')
              })
              .catch(this.notifyError)
          }
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private handleClose () {
    this.$emit('close')
  }

  @Watch('visible')
  private watchVisible (value: boolean) {
    if (value) {
      this.mentor = !this.isSpecialCourse && this.masterGroup && this.masterGroup.mentors.length ? this.masterGroup.mentors[0] : null
      this.mentors = this.masterGroup ? [...this.masterGroup.mentors] : []
      this.syncForm()
    }
  }
}
