































































































import { isEqual } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Ref, Component } from 'vue-property-decorator'
import axios, { CancelTokenSource } from 'axios'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import EditMasterGroupModal from '@/components/modals/masterGroups/EditMasterGroupModal.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
// store
import DictionaryModule from '@/store/modules/dictionary'
import MentorGroupsModule from '@/store/modules/mentor/groups'
// types
import {
  IMasterGroupsFilter,
  IMasterGroupsFilterType,
  MasterGroupShortResource,
  MasterShortResource,
  NameValueResource,
  PaginatorMeta,
} from '@/store/types'
import { tableFooterOptions } from '@/utils/constants'
import ManagerGroupsModule from '@/store/modules/manager/groups'
import TextInput from '@/components/_uikit/controls/TextInput.vue'

interface ILoadedGroup {
  id: number,
  masters: MasterShortResource[],
}

@Component({
  components: {
    Confirmation,
    EditMasterGroupModal,
    Select,
    TableFooter,
    Tag,
    TextInput,
    UserSocialCard,
  },
})
export default class EducationGroupsTable extends NotifyMixin {
  @Ref() confirm!: Confirmation

  private footerOptions = tableFooterOptions
  private showEditMasterGroupModal = false
  private masterGroupsExpanded = []
  private loadedGroups: ILoadedGroup[] = []
  private selectedGroup: MasterGroupShortResource | null = null

  private get masterGroups (): MasterGroupShortResource[] {
    return MentorGroupsModule.groups.data
  }

  private get pagination (): PaginatorMeta {
    return MentorGroupsModule.groups.meta
  }

  private get groupTypes (): NameValueResource[] {
    return DictionaryModule.groupTypes
  }

  private get subjects (): NameValueResource[] {
    return DictionaryModule.subjects
  }

  private get headers () {
    return [
      { text: 'Название', value: 'title' },
      { text: 'Курс', value: 'course' },
      { text: 'Тип группы', value: 'groupType' },
      { text: 'Мастера', value: 'mastersCount' },
      { text: '', value: 'actions' },
    ]
  }

  // filters
  private get filter (): IMasterGroupsFilter {
    return MentorGroupsModule.groupsFilter
  }

  private set filter (filter: IMasterGroupsFilter) {
    if (!isEqual(filter, this.filter) || this.masterGroups.length === 0) {
      this.masterGroupsExpanded = []
      this.loadedGroups = []
      MentorGroupsModule.setGroupsFilter(filter)
      this.fetchMasterGroups(true)
    }
  }

  private isLoading = false
  private source: CancelTokenSource | null = null

  private handleFilter (field: IMasterGroupsFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private handleEditGroup (group: MasterGroupShortResource) {
    ManagerGroupsModule.fetchGroup(group.id)
      .then(() => {
        this.selectedGroup = group
        this.showEditMasterGroupModal = true
      })
      .catch(this.notifyError)
  }

  private confirmDeleteGroup (group: MasterGroupShortResource) {
    this.confirm.open(
      'Удаление группы мастеров',
      `Вы действительно хотите удалить <span class="text-body-3 secondary--text">${group.title}</span>? После удаления группу невозможно будет восстановить.`,
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(() => {
        this.handleDeleteGroup(group)
      })
      .catch(() => {return})
  }

  private handleDeleteGroup (group: MasterGroupShortResource) {
    ManagerGroupsModule.deleteGroup(group.id)
      .then(() => {
        this.fetchMasterGroups()
        this.notifySuccess('Группа удалена')
      })
      .catch(this.notifyError)
  }

  private getGroupMasters (groupID: number) {
    const group = this.loadedGroups.find((group: ILoadedGroup) => groupID === group.id)
    return group ? group.masters : []
  }

  private handleExpandGroup (payload: { item: MasterGroupShortResource, value: boolean }) {
    if (payload.value && !this.loadedGroups.find((group: ILoadedGroup) => payload.item.id === group.id)) {
      this.fetchMasters(payload.item.id)
    }
  }

  // isFilter - флаг указывает на то, запускается ли метод после изменения фильтрации
  @Bind
  @Debounce(300)
  private fetchMasterGroups(isFilter = false) {
    const { CancelToken } = axios
    if (this.isLoading && this.source) {
      this.source.cancel()
    }
    this.source = CancelToken.source()
    this.isLoading = true
    MentorGroupsModule.fetchGroups(this.source.token)
      .then(() => {
        this.isLoading = false
        if (!isFilter) {
          this.masterGroupsExpanded = []
          this.loadedGroups = []
          this.showEditMasterGroupModal = false
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          this.notifyError(err)
          this.isLoading = false
        }
      })
  }

  // private fetchMasterGroups () {
  //   MentorGroupsModule.fetchGroups()
  //     .then(() => {
  //       this.masterGroupsExpanded = []
  //       this.loadedGroups = []
  //       this.showEditMasterGroupModal = false
  //     })
  //     .catch(this.notifyError)
  // }

  private fetchMasters (groupID: number) {
    MentorGroupsModule.fetchGroupMasters(groupID)
      .then((response: MasterShortResource[]) => {
        this.loadedGroups.push({
          id: groupID,
          masters: [...response],
        })
      })
      .catch(this.notifyError)
  }
}
