import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { CancelToken } from 'axios'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  IMasterGroupsFilter,
  ManagerMasterGroupsGetRequest, ManagerMasterGroupsMasterGroupIdMastersGetRequest,
  MasterGroupShortResourcePaginator,
} from '@/store/types'
import { tableOptionsToParams } from '@/utils/functions'

/**
 * Работа с мастер-группами мастера для наставника
 * - получение списка МГ
 */

@Module({
  dynamic: true,
  name: 'MentorGroups',
  namespaced: true,
  store,
})
class MentorGroups extends VuexModule {
  // ---------------------------- Groups ---------------------------- >>
  // Filter
  groupsFilter: IMasterGroupsFilter = Object.assign({}, { ...GET_DEFAULT_TABLE_FILTER(), itemsPerPage: 10 })

  @Mutation
  setGroupsFilter (payload: IMasterGroupsFilter) {
    this.groupsFilter = Object.assign({}, payload)
  }

  // Entities
  groups: MasterGroupShortResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setGroups (payload: MasterGroupShortResourcePaginator) {
    this.groups = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchGroups(cancelToken?: CancelToken) {
    const { data } = await ManagerMasterGroupsGetRequest(tableOptionsToParams(this.groupsFilter), { cancelToken })
    this.setGroups(data)
    return data
  }

  @Action({ rawError: true })
  async fetchGroupMasters(masterGroupID: number) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersGetRequest(masterGroupID)
    return data
  }
}

const MentorGroupsModule = getModule(MentorGroups)

export default MentorGroupsModule
