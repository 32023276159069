var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"education-groups"},[_c('div',{staticClass:"education-groups__filters space-b-6"},[_c('Select',{attrs:{"options":_vm.subjects,"label":"Предмет","placeholder":"Выберите предмет","clearable":true,"outlined":"","dense":"","rounded":""},on:{"input":function (value) { return _vm.handleFilter('subjectId', value); }},model:{value:(_vm.filter.subjectId),callback:function ($$v) {_vm.$set(_vm.filter, "subjectId", $$v)},expression:"filter.subjectId"}}),_c('Select',{attrs:{"options":_vm.groupTypes,"label":"Тип группы","placeholder":"Выберите тип группы","clearable":true,"outlined":"","dense":"","rounded":""},on:{"input":function (value) { return _vm.handleFilter('groupType', value); }},model:{value:(_vm.filter.groupType),callback:function ($$v) {_vm.$set(_vm.filter, "groupType", $$v)},expression:"filter.groupType"}}),_c('TextInput',{attrs:{"placeholder":"Название","label":"Название","append-icon":"$search","dense":"","outlined":"","rounded":"","clearable":true},on:{"input":function (value) { return _vm.handleFilter('query', value); }},model:{value:(_vm.filter.query),callback:function ($$v) {_vm.$set(_vm.filter, "query", $$v)},expression:"filter.query"}})],1),_c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.masterGroups,"options":_vm.filter,"server-items-length":_vm.pagination.total,"footer-props":_vm.footerOptions,"hide-default-footer":"","disable-sort":true,"expanded":_vm.masterGroupsExpanded},on:{"update:options":function($event){_vm.filter=$event},"update:expanded":function($event){_vm.masterGroupsExpanded=$event},"item-expanded":_vm.handleExpandGroup},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
var index = ref.index;
var item = ref.item;
return [_c('tr',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]}),_c('tr',{staticClass:"education-groups__row",class:{ 'education-groups_expanded': isExpanded }},[_c('td',{staticClass:"teal--text text--darken-4",class:{ 'text-body-3': isExpanded },attrs:{"width":"33%"}},[_vm._v(_vm._s(item.title))]),_c('td',{attrs:{"width":"33%"}},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(item.course.title))])]),_c('td',{staticClass:"teal--text text--darken-4",attrs:{"width":"16%"}},[_c('Tag',{attrs:{"type":"bright","groupType":item.group.type.value}},[_vm._v(_vm._s(item.group.type.name))])],1),_c('td',{attrs:{"width":"8%"}},[_vm._v(_vm._s(item.mastersCount))]),_c('td',{attrs:{"width":"10%"}},[_c('div',{staticClass:"data-table__actions"},[(item.mastersCount === 0)?_c('ButtonIconAction',{staticClass:"education-groups__action",attrs:{"icon":"$trash"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.confirmDeleteGroup(item)}}}):_vm._e(),_c('ButtonIconAction',{staticClass:"education-groups__action",attrs:{"icon":"$edit"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.handleEditGroup(item)}}}),_c('VIcon',{staticClass:"v-data-table__expand-icon",class:{ 'v-data-table__expand-icon--active': isExpanded },on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v("$arrowDown")])],1)])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('tr',{staticClass:"expanded-row"},[_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"expanded-row__header"},[_c('div',{staticClass:"text-body-1"},[_vm._v("Ссылка на группу: "+_vm._s(item.groupUrl || 'Не указана'))]),_c('div',{staticClass:"text-body-1"},[_vm._v("Ссылка на чат: "+_vm._s(item.chatUrl || 'Не указана'))])])])]),_c('tr',{staticClass:"expanded-row"},[_c('td',{staticStyle:{"padding-left":"0!important","padding-right":"0!important"},attrs:{"colspan":headers.length}},[_c('VSimpleTable',{staticClass:"expanded-table rounded-off"},[_c('tbody',_vm._l((_vm.getGroupMasters(item.id)),function(master){return _c('tr',{key:master.user.id,staticClass:"expanded-table__row"},[_c('td',{staticClass:"expanded-table__cell",attrs:{"width":"33%"}},[_c('UserSocialCard',{attrs:{"user":master.user}})],1),_c('td',{staticClass:"expanded-table__cell",attrs:{"width":"33%","colspan":"2"}},[_c('div',{staticClass:"teal--text text--darken-4"},[_vm._v(_vm._s(master.user.email))])]),_c('td',{staticClass:"expanded-table__cell",attrs:{"width":"24%"}},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(master.user.city))])])])}),0)])],1)])]}},{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('TableFooter',{attrs:{"props":props,"filter":_vm.filter},on:{"update:filter":function($event){_vm.filter=$event}}})]}},{key:"no-data",fn:function(){return [_c('NoDataFound',{attrs:{"skin":"grey"}})]},proxy:true}])}),_c('Confirmation',{ref:"confirm"}),(_vm.selectedGroup)?_c('EditMasterGroupModal',{attrs:{"visible":_vm.showEditMasterGroupModal,"masterGroup":_vm.selectedGroup},on:{"update:visible":function($event){_vm.showEditMasterGroupModal=$event},"success":_vm.fetchMasterGroups,"close":function($event){_vm.showEditMasterGroupModal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }